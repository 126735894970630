import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import {createFileRoute} from '@tanstack/react-router';
import {generateHTML} from '@tiptap/core';
import * as Icon from '@workhub/icons';
import {IconButton, Loading} from '@workhub/ui';
import dayjs from 'dayjs';
import {memo, useEffect, useMemo} from 'react';
import ReactMarkdown from 'react-markdown';

import {markAnnouncementAsReadApi} from '@/api-call/workhub-core/markAnnouncementAsReadApi';
import {useAnnouncements} from '@/common/hooks/useAnnouncements';
import useDict, {useCommonDict} from '@/common/hooks/useDict';
import announcementSlice from '@/common/redux/slices/announcementSlice';
import {AnnouncementState} from '@/common/redux/state-types/announcementState';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {useAsyncDispatch} from '@/common/redux/store/store';
import {WHFontCss} from '@/common/styles/whFont';
import WCardBackground from '@/components/figma/card/WCardBackground';
import WHeaderNavigation, {PropBreadcrumb} from '@/components/header/WHeaderNavigation';
import {RichTextHtmlViewer} from '@/components/rich-text-editor/RichTextHtmlViewer';
import {extensions} from '@/features/building-tenant-management/announcement/extentions';
import {Authorize} from '@/routing/Authorize';
import {AnnouncementService} from '@/services/announcement/announcementService';

export const Route = createFileRoute('/_authorized/announcements/$id')({
  component: () => (
    <Authorize featureGroup='Announcement' feature='announcement'>
      <RouteComponent />
    </Authorize>
  ),
});

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const dictDef = {
  announcement: {
    default: {
      default: 'お知らせ /',
      [Locale.en_US]: 'Announcement /',
    },
  },
  title: {
    default: {
      default: (publisherName: string) => `${publisherName} からのお知らせ`,
      [Locale.en_US]: (publisherName: string) => `Announcement from ${publisherName}`,
    },
  },
  backToList: {
    default: {
      default: '一覧に戻る',
      [Locale.en_US]: 'Back to list',
    },
  },
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-16);
`;

const FlexWrapper = styled.div`
  padding: var(--spacing-16) 0;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  overflow: hidden;
  color: var(--text-neutral-primary);
  text-overflow: ellipsis;
  ${WHFontCss.titleLarge}
`;

const PublishedAt = styled.div`
  color: var(--text-neutral-secondary);
  ${WHFontCss.labelMedium}
`;

const MarkDownAreaWrapper = styled.div`
  padding: var(--spacing-24) 0;
`;

const ContentWrapper = styled.div`
  padding: var(--spacing-24);
`;

type Announcement = AnnouncementState['items'][number];

function RouteComponent() {
  const dict = useDict(dictDef);
  const commonDict = useCommonDict();
  const params = Route.useParams();
  const navigate = Route.useNavigate();
  const {items: announcements} = useAnnouncements();

  const announcement = useMemo<Announcement | undefined>(() => {
    const id = params.id;
    return announcements.find(a => a.id === id);
  }, [announcements, params.id]);

  useReadAnnouncement(announcement);

  const navigation: PropBreadcrumb = useMemo(
    () => [
      {
        label: dict.announcement,
        toPath: '/announcements',
      },
    ],
    [dict.announcement]
  );

  return (
    <Root>
      {!announcement ? (
        <Loading centered />
      ) : (
        <>
          <WHeaderNavigation
            title={
              <TitleWrapper>
                <IconButton title={dict.backToList} onClick={() => navigate({to: '/announcements'})}>
                  <Icon.Arrow.Back />
                </IconButton>
                <>{dict.title(announcement.publishedBy.name)}</>
              </TitleWrapper>
            }
            navigation={navigation}
          />
          <ContentWrapper>
            <WCardBackground width={'100%'}>
              <FlexWrapper>
                <Title>{announcement.title}</Title>
                <PublishedAt>
                  {announcement.publishedAt
                    ? dayjs(announcement.publishedAt).format('YYYY/MM/DD HH:mm')
                    : commonDict.noData}
                </PublishedAt>
              </FlexWrapper>
              <Divider />
              <MarkDownAreaWrapper>
                <AnnouncementContent announcement={announcement} />
              </MarkDownAreaWrapper>
            </WCardBackground>
          </ContentWrapper>
        </>
      )}
    </Root>
  );
}

interface AnnouncementContentProps {
  announcement: Pick<AnnouncementState['items'][number], 'content' | 'jsonContent'>;
}

const AnnouncementContent = memo<AnnouncementContentProps>(function AnnouncementContent({announcement}) {
  if (announcement.content) {
    return <ReactMarkdown>{announcement.content}</ReactMarkdown>;
  }

  if (announcement.jsonContent) {
    return <RichTextHtmlViewer html={generateHTML(announcement.jsonContent, extensions)} />;
  }

  return null;
});

const useReadAnnouncement = (announcement: Announcement | undefined) => {
  const dispatch = useAsyncDispatch();

  useEffect(() => {
    if (announcement && !announcement.read) {
      dispatch(announcementSlice.actions.read({id: announcement.id}));
      // firestore で管理されているお知らせと RDB で管理されているお知らせで処理を分ける
      if (announcement.jsonContent) {
        markAnnouncementAsReadApi({paths: {publishedAnnouncementId: announcement.id}});
      } else {
        AnnouncementService.readAnnouncement(announcement.id);
      }
    }
  }, [announcement, dispatch]);
};
