import styled from '@emotion/styled';
import {useMutation, useQuery} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import {generateHTML} from '@tiptap/core';
import * as Icon from '@workhub/icons';
import {Alert, Dialog, IconButton, Loading, TextButton} from '@workhub/ui';
import {useMemo} from 'react';

import {getAnnouncementApi, type GetAnnouncementResponse} from '@/api-call/workhub-core/getAnnouncementApi';
import {GetAnnouncementsResponse} from '@/api-call/workhub-core/getAnnouncementsApi';
import {updateAnnouncementApi} from '@/api-call/workhub-core/updateAnnouncementApi';
import StoragePeople from '@/common/firebase/storage/reference/organizations/people/storagePeople';
import {useDialog} from '@/common/hooks/useDialog';
import useDict, {useCommonDict} from '@/common/hooks/useDict';
import {useLocale} from '@/common/hooks/useLocale';
import {useOrganization} from '@/common/hooks/useOrganization';
import {useSnackbar} from '@/common/hooks/useSnackbar';
import {jpDate} from '@/common/jpDate';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {WHFont} from '@/common/styles/whFont';
import {LocaleUtils} from '@/common/utils/localeUtils';
import {TimeUtils} from '@/common/utils/timeUtils';
import WCardTemplate from '@/components/figma/card/WCardTemplate';
import WHeaderNavigation, {BreadcrumbLink} from '@/components/header/WHeaderNavigation';
import {WAvatarRenderer} from '@/components/image/WAvatorRenderer';
import {RichTextHtmlViewer} from '@/components/rich-text-editor/RichTextHtmlViewer';
import AnnouncementPublishSettingFormDialog, {
  BuildingAnnouncementPublishSettingValue,
} from '@/features/building-tenant-management/announcement/AnnouncementPublishSettingFormDialog';
import CellStatus from '@/features/building-tenant-management/announcement/cells/CellStatus';
import {extensions} from '@/features/building-tenant-management/announcement/extentions';
import {Authorize} from '@/routing/Authorize';

const dictDef = {
  buildingTenantManagement: {
    default: {
      default: 'ビルテナント管理',
      [Locale.en_US]: 'Building Tenant Management',
    },
  },
  announcementManagement: {
    default: {
      default: 'お知らせ管理',
      [Locale.en_US]: 'Announcement Management',
    },
  },
  preparedForPublishAlertTitle: {
    default: {
      default: '公開予約時刻が近づいているため、お知らせのステータスが公開準備に移行しました。',
      [Locale.en_US]: 'The announcement status has moved to "Preparing" because the scheduled time is approaching.',
    },
  },
  preparedForPublishAlertDescription: {
    default: {
      default: '公開準備中のためお知らせ内容の変更ができません。',
      [Locale.en_US]: 'The announcement is not editable because it is preparing.',
    },
  },
  announcementContent: {
    default: {
      default: 'お知らせ内容',
      [Locale.en_US]: 'Announcement Content',
    },
  },
  preview: {
    default: {
      default: 'プレビュー',
      [Locale.en_US]: 'Preview',
    },
  },
  publishSetting: {
    default: {
      default: '公開設定',
      [Locale.en_US]: 'Publish Setting',
    },
  },
  proceedToPublish: {
    default: {
      default: '公開に進む',
      [Locale.en_US]: 'Proceed to publish',
    },
  },
  succeededToEditAnnouncement: {
    default: {
      default: 'お知らせを編集しました。公開するには公開設定を行ってください。',
      [Locale.en_US]: 'Announcement edited. Please set the publish setting to publish.',
    },
  },
  succeededToSchedule: {
    default: {
      default: 'お知らせを編集しました。予約日時に自動的に公開されます。',
      [Locale.en_US]: 'Announcement edited. It will be automatically published on scheduled time.',
    },
  },
  succeededToPublish: {
    default: {
      default: 'お知らせを公開しました。',
      [Locale.en_US]: 'Announcement published.',
    },
  },
  failedToEdit: {
    default: {
      default: '編集に失敗しました',
      [Locale.en_US]: 'Failed to edit',
    },
  },
  status: {
    default: {
      default: 'ステータス',
      [Locale.en_US]: 'Status',
    },
  },
  scheduledDate: {
    default: {
      default: '公開予約日時',
      [Locale.en_US]: 'Scheduled Date',
    },
  },
  publishTimeNotice: {
    default: {
      default: (duration: string) => `${duration}後に公開予定`,
      [Locale.en_US]: (duration: string) => `Scheduled to be published in ${duration}`,
    },
  },
  publishScope: {
    default: {
      default: '公開範囲（テナント）',
      [Locale.en_US]: 'Publish Scope',
    },
  },
  allTenants: {
    default: {
      default: 'すべてのテナント',
      [Locale.en_US]: 'All Tenants',
    },
  },
  updateInformation: {
    default: {
      default: '更新情報',
      [Locale.en_US]: 'Update information',
    },
  },
  lastModifiedDate: {
    default: {
      default: '最終編集日時',
      [Locale.en_US]: 'Last Modified Date',
    },
  },
  createdDate: {
    default: {
      default: '作成日時',
      [Locale.en_US]: 'Created Date',
    },
  },
  edit: {
    default: {
      default: '編集',
      [Locale.en_US]: 'Edit',
    },
  },
  back: {
    default: {
      default: '戻る',
      [Locale.en_US]: 'Back',
    },
  },
  publishStatus: {
    default: {
      default: (status: GetAnnouncementResponse['status']) => {
        switch (status) {
          case 'published':
            return '公開';
          case 'draft':
            return '下書き';
          case 'preparing':
            return '公開準備';
          case 'scheduled':
            return '公開予約';
          case 'canceled':
            return '公開取消';
          default: {
            const _: never = status;
            return '';
          }
        }
      },
      [Locale.en_US]: (status: GetAnnouncementResponse['status']) => {
        switch (status) {
          case 'published':
            return 'Published';
          case 'draft':
            return 'Draft';
          case 'preparing':
            return 'Preparing';
          case 'scheduled':
            return 'Scheduled';
          case 'canceled':
            return 'Canceled';
          default: {
            const _: never = status;
            return '';
          }
        }
      },
    },
  },
  preparingErrorDialogTitle: {
    default: {
      default: 'お知らせのステータスが「公開準備中」に移行したため、現在は内容の編集ができません。',
      [Locale.en_US]: 'The announcement status has moved to "Preparing", so you cannot edit it at this time.',
    },
  },
  preparingErrorDialogDescription: {
    default: {
      default:
        '公開予約時刻が迫っているため、お知らせのステータスが「公開準備中」に移行しました。このため、現在は内容の編集ができません。',
      [Locale.en_US]:
        'The announcement status has moved to "Preparing" because the scheduled time is approaching. Therefore, you cannot edit it at this time.',
    },
  },
  succeededToUpdateAsDraft: {
    default: {
      default: 'ステータスを下書きに変更しました',
      [Locale.en_US]: 'The publish status is changed to draft.',
    },
  },
  failedToUpdateAsDraft: {
    default: {
      default: '下書きへの変更に失敗したため、編集できません',
      [Locale.en_US]: 'Failed to change the status to draft, so you cannot edit it.',
    },
  },
  ok: {
    default: {
      default: 'OK',
      [Locale.en_US]: 'OK',
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: var(--spacing-16);
`;

const AlertWrapper = styled.div`
  padding: var(--spacing-24) var(--spacing-24) 0;
`;

const Layout = styled.div`
  display: flex;
  column-gap: var(--spacing-24);
  padding: var(--spacing-24);
  overflow: auto;
`;

const BodyWrapper = styled.div`
  padding: var(--spacing-24) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  height: calc(100% - 48px);
  box-sizing: border-box;
`;

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  row-gap: var(--spacing-24);
  width: 320px;
`;

const AsideLabel = styled.dt`
  ${WHFont.labelMediumStrong}
  color: var(--text-neutral-secondary);
`;

const AsideDetail = styled.dd`
  margin-top: var(--spacing-8);
  margin-left: 0;

  + dt {
    margin-top: var(--spacing-16);
  }
`;

const ScheduledAtDescription = styled.p`
  display: block;
  margin-top: var(--spacing-4);
  ${WHFont.bodySmall}
  color: var(--text-neutral-secondary);
`;

export const Route = createFileRoute('/_authorized/building-tenant-management/announcement/$announcementId/')({
  component: () => (
    <Authorize featureGroup='BuildingTenantManagement' feature='BuildingAnnouncementManagement'>
      <RouteComponent />
    </Authorize>
  ),
});

function RouteComponent() {
  const dict = useDict(dictDef);
  const locale = useLocale();
  const navigate = Route.useNavigate();
  const {announcementId} = Route.useParams();
  const {id: organizationId} = useOrganization();
  const {open: openPublishSetting, onOpen: onOpenPublishSetting, onClose: onClosePublishSetting} = useDialog();
  const {
    open: openPreparingErrorDialog,
    onOpen: onOpenPreparingErrorDialog,
    onClose: onClosePreparingErrorDialog,
  } = useDialog();
  const snackbar = useSnackbar();

  const navigation = useMemo(() => {
    const breadcrumbs: BreadcrumbLink[] = [
      {
        label: dict.buildingTenantManagement,
      },
      {
        label: dict.announcementManagement,
        toPath: '/building-tenant-management/announcement',
      },
    ];
    return breadcrumbs;
  }, [dict.announcementManagement, dict.buildingTenantManagement]);

  const {data: announcement, isLoading} = useQuery({
    queryKey: ['building-tenant-management/announcement', announcementId],
    queryFn: async () => {
      const {data} = await getAnnouncementApi({
        paths: {announcementId},
      });
      return data;
    },
  });

  const {mutate: update, isPending} = useMutation({
    mutationKey: ['updateAnnouncement'],
    mutationFn: async (value: BuildingAnnouncementPublishSettingValue) => {
      if (!announcement) {
        return;
      }
      await updateAnnouncementApi({
        paths: {announcementId},
        body: {
          title: announcement.title,
          content: announcement.content,
          publishImmediately: value.publishStatus === 'published',
          scheduledAt: value.publishStatus === 'scheduled' ? value.scheduledAt?.toISOString() : undefined,
        },
      });
      return value.publishStatus;
    },
    onSuccess: publishStatus => {
      if (!publishStatus) {
        return;
      }
      const snackBarMessage = (() => {
        switch (publishStatus) {
          case 'draft':
            return dict.succeededToEditAnnouncement;
          case 'scheduled':
            return dict.succeededToSchedule;
          case 'published':
            return dict.succeededToPublish;
        }
      })();
      snackbar.success(snackBarMessage);
      navigate({to: '/building-tenant-management/announcement'});
    },
    onError: () => {
      snackbar.fail(dict.failedToEdit);
    },
  });

  const {mutate: temporarilyUpdateAsDraft} = useMutation({
    mutationKey: ['temporarilyUpdateAnnouncementAsDraft'],
    mutationFn: async () => {
      if (!announcement) {
        return;
      }
      await updateAnnouncementApi({
        paths: {announcementId},
        body: {
          title: announcement.title,
          content: announcement.content,
          scheduledAt: undefined,
        },
      });
    },
    onSuccess: () => {
      snackbar.info(dict.succeededToUpdateAsDraft);
      navigate({
        to: `/building-tenant-management/announcement/${announcementId}/edit`,
      });
    },
    onError: () => {
      snackbar.fail(dict.failedToUpdateAsDraft);
      return null;
    },
  });

  const canProceedToEdit = useMemo(() => {
    return (
      announcement && (!announcement.scheduledAt || jpDate(announcement.scheduledAt).isAfter(jpDate().add(10, 'm')))
    );
  }, [announcement]);

  if (isLoading) {
    return <Loading centered />;
  }

  if (!announcement) {
    return null;
  }

  return (
    <Root>
      <WHeaderNavigation
        title={
          <TitleWrapper>
            <IconButton
              title={dict.back}
              onClick={() => navigate({to: '/building-tenant-management/announcement'})}
              disableTooltip
            >
              <Icon.Arrow.Back />
            </IconButton>
            <>{announcement.title}</>
          </TitleWrapper>
        }
        navigation={navigation}
      />
      {announcement.status === 'preparing' && (
        <AlertWrapper>
          <Alert type={'info'} title={dict.preparedForPublishAlertTitle}>
            {dict.preparedForPublishAlertDescription}
          </Alert>
        </AlertWrapper>
      )}
      <Layout>
        <WCardTemplate
          title={dict.announcementContent}
          width={'100%'}
          iconButton={
            (announcement?.status === 'draft' || announcement?.status === 'scheduled') && (
              <IconButton
                title={dict.edit}
                onClick={async () => {
                  if (canProceedToEdit) {
                    if (announcement && announcement.status !== 'draft') {
                      await temporarilyUpdateAsDraft();
                    } else {
                      navigate({
                        to: `/building-tenant-management/announcement/${announcementId}/edit`,
                      });
                    }
                  } else {
                    onOpenPreparingErrorDialog();
                  }
                }}
              >
                <Icon.Edit />
              </IconButton>
            )
          }
        >
          <BodyWrapper>
            <RichTextHtmlViewer html={generateHTML(announcement.content, extensions)} />
          </BodyWrapper>
        </WCardTemplate>
        <Aside>
          <WCardTemplate
            title={dict.publishSetting}
            width={'100%'}
            iconButton={
              announcement?.status === 'draft' ? (
                <TextButton color={'secondary'} onClick={onOpenPublishSetting}>
                  {dict.proceedToPublish}
                </TextButton>
              ) : announcement?.status === 'scheduled' ? (
                <IconButton
                  title={dict.edit}
                  onClick={() => {
                    if (canProceedToEdit) {
                      onOpenPublishSetting();
                    } else {
                      onOpenPreparingErrorDialog();
                    }
                  }}
                >
                  <Icon.Edit />
                </IconButton>
              ) : null
            }
          >
            <dl>
              <AsideLabel>{dict.status}</AsideLabel>
              <AsideDetail>
                <CellStatus status={announcement.status}>{dict.publishStatus(announcement.status)}</CellStatus>
              </AsideDetail>
              {announcement.status === 'scheduled' && announcement.scheduledAt && (
                <>
                  <AsideLabel>{dict.scheduledDate}</AsideLabel>
                  <AsideDetail>
                    {TimeUtils.timestampToFormatString(announcement.scheduledAt)}
                    <ScheduledAtDescription>
                      {dict.publishTimeNotice(
                        TimeUtils.formatDuration(
                          TimeUtils.durationFromTo({
                            from: jpDate().valueOf(),
                            to: jpDate(announcement.scheduledAt).valueOf(),
                          }),
                          locale
                        )
                      )}
                    </ScheduledAtDescription>
                  </AsideDetail>
                </>
              )}
            </dl>
          </WCardTemplate>
          <WCardTemplate title={dict.updateInformation} width={'100%'}>
            <dl>
              <AsideLabel>{dict.lastModifiedDate}</AsideLabel>
              <AsideDetail>
                {TimeUtils.timestampToFormatString(announcement.modifiedAt)}
                <PeopleCardComponent organizationId={organizationId} people={announcement.modifiedBy} />
              </AsideDetail>
              <AsideLabel>{dict.createdDate}</AsideLabel>
              <AsideDetail>
                {TimeUtils.timestampToFormatString(announcement.createdAt)}
                <PeopleCardComponent organizationId={organizationId} people={announcement.createdBy} />
              </AsideDetail>
            </dl>
          </WCardTemplate>
        </Aside>
      </Layout>

      <AnnouncementPublishSettingFormDialog
        open={openPublishSetting}
        inputValue={{
          publishStatus: announcement.status,
          scheduledAt: announcement.scheduledAt ? jpDate(announcement.scheduledAt) : null,
        }}
        onOk={value => update(value)}
        onClose={onClosePublishSetting}
        isPending={isPending}
      />

      <Dialog open={openPreparingErrorDialog}>
        <Dialog.Title>{dict.preparingErrorDialogTitle}</Dialog.Title>
        <Dialog.Content>{dict.preparingErrorDialogDescription}</Dialog.Content>
        <Dialog.Actions>
          <TextButton color='primary' onClick={onClosePreparingErrorDialog}>
            {dict.ok}
          </TextButton>
        </Dialog.Actions>
      </Dialog>
    </Root>
  );
}

const PeopleCard = styled.div`
  margin-top: var(--spacing-8);
  padding: var(--spacing-12);
  background-color: var(--surface-neutral-middle);
  border-radius: var(--radius-m);
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
`;

const IconWrapper = styled.span`
  flex: 0 0 30px;
`;

const Name = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-neutral-primary);
  ${WHFont.bodyMedium}
`;

const PeopleCardComponent = ({
  organizationId,
  people,
}: {
  organizationId: string;
  people?:
    | GetAnnouncementsResponse['items'][number]['createdBy']
    | GetAnnouncementsResponse['items'][number]['modifiedBy'];
}) => {
  const commonDict = useCommonDict();
  const locale = useLocale();

  return (
    <PeopleCard>
      <Wrapper>
        <IconWrapper>
          <WAvatarRenderer
            width={30}
            height={30}
            name={LocaleUtils.getLocaleName({nameJp: people?.nameJp, nameEn: people?.nameEn}, locale)}
            filePath={
              people?.id && people.iconUrl
                ? StoragePeople.getImagePath(organizationId, people.id, people.iconUrl)
                : undefined
            }
          />
        </IconWrapper>
        <Name>
          {people?.nameJp || people?.nameEn
            ? LocaleUtils.getLocaleName({nameJp: people.nameJp, nameEn: people.nameEn}, locale)
            : commonDict.noData}
        </Name>
      </Wrapper>
    </PeopleCard>
  );
};
