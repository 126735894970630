import styled from '@emotion/styled';
import {useMutation} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import {ComponentRef, useEffect, useRef, useState} from 'react';

import {createAnnouncementApi} from '@/api-call/workhub-core/createAnnouncementApi';
import {useDialog} from '@/common/hooks/useDialog';
import useDict from '@/common/hooks/useDict';
import {useSnackbar} from '@/common/hooks/useSnackbar';
import {Locale} from '@/common/redux/state-types/localeStateType';
import WHeaderNavigation from '@/components/header/WHeaderNavigation';
import {RichTextEditor} from '@/components/rich-text-editor/RichTextEditor';
import AnnouncementConfirmCancelDialog from '@/features/building-tenant-management/announcement/AnnouncementConfirmCancelDialog';
import AnnouncementPublishSettingFormDialog, {
  BuildingAnnouncementPublishSettingValue,
} from '@/features/building-tenant-management/announcement/AnnouncementPublishSettingFormDialog';
import AnnouncementTitleAndContentForm from '@/features/building-tenant-management/announcement/AnnouncementTitleAndContentForm';
import {Authorize} from '@/routing/Authorize';

const dictDef = {
  navigation1: {
    default: {
      default: 'ビルテナント管理',
      [Locale.en_US]: 'Building Tenant Management',
    },
  },
  navigation2: {
    default: {
      default: 'お知らせ管理',
      [Locale.en_US]: 'Announcement Management',
    },
  },
  newAnnouncement: {
    default: {
      default: 'お知らせ',
      [Locale.en_US]: 'Announcement',
    },
  },
  save: {
    default: {
      default: '保存',
      [Locale.en_US]: 'Save',
    },
  },
  cancel: {
    default: {
      default: 'キャンセル',
      [Locale.en_US]: 'Cancel',
    },
  },
  toPublishSetting: {
    default: {
      default: '続けて公開設定へ',
      [Locale.en_US]: 'To Publish Setting',
    },
  },
  succeededToSaveAsDraft: {
    default: {
      default: 'お知らせを保存しました。公開するには公開設定を行ってください。',
      [Locale.en_US]: 'Announcement saved. Please set the publish setting to publish.',
    },
  },
  succeededToSchedule: {
    default: {
      default: 'お知らせを保存しました。予約日時に自動的に公開されます。',
      [Locale.en_US]: 'Announcement saved. It will be automatically published on scheduled time.',
    },
  },
  succeededToPublish: {
    default: {
      default: 'お知らせを公開しました。',
      [Locale.en_US]: 'Announcement published.',
    },
  },
  failedToSave: {
    default: {
      default: '保存に失敗しました',
      [Locale.en_US]: 'Failed to save',
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
`;

const Layout = styled.div`
  padding: var(--spacing-24);
  flex: 1;
  overflow: auto;
`;

export const Route = createFileRoute('/_authorized/building-tenant-management/announcement/create')({
  component: () => (
    <Authorize featureGroup='BuildingTenantManagement' feature='BuildingAnnouncementManagement'>
      <RouteComponent />
    </Authorize>
  ),
});

function RouteComponent() {
  const dict = useDict(dictDef);
  const snackbar = useSnackbar();
  const navigate = Route.useNavigate();
  const {open: openPublishSetting, onOpen: onOpenPublishSetting, onClose: onClosePublishSetting} = useDialog();

  const editorRef = useRef<ComponentRef<typeof RichTextEditor>>(null);
  const titleRef = useRef<HTMLInputElement>(null);

  const [isTitleOverFifty, setIsTitleOverFifty] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [bothChanged, setBothChanged] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTitle = titleRef.current?.value;
      const hasTitleChanged = Boolean(titleRef.current?.value);
      const hasEditorChanged = editorRef.current?.getIsEmpty() === false;

      setIsTitleOverFifty(currentTitle !== undefined && currentTitle.length > 50);
      setHasChanged(hasTitleChanged || hasEditorChanged);
      setBothChanged(hasTitleChanged && hasEditorChanged);
    }, 1000); // 1000msごとにチェック

    return () => clearInterval(intervalId);
  }, []);

  const {mutate: save, isPending} = useMutation({
    mutationKey: ['createAnnouncement'],
    mutationFn: async (value: BuildingAnnouncementPublishSettingValue) => {
      const title = titleRef.current?.value;
      const content = editorRef.current?.getJson();
      const contentIsEmpty = editorRef.current?.getIsEmpty();
      if (!title || !content || contentIsEmpty) {
        throw new Error('content is empty');
      }
      await createAnnouncementApi({
        body: {
          title,
          content,
          publishImmediately: value.publishStatus === 'published',
          scheduledAt: value.publishStatus === 'scheduled' ? value.scheduledAt?.toISOString() : undefined,
        },
      });
      return value.publishStatus;
    },
    onSuccess: publishStatus => {
      const snackBarMessage = (() => {
        switch (publishStatus) {
          case 'draft':
            return dict.succeededToSaveAsDraft;
          case 'scheduled':
            return dict.succeededToSchedule;
          case 'published':
            return dict.succeededToPublish;
        }
      })();
      snackbar.success(snackBarMessage);
      navigate({to: '/building-tenant-management/announcement'});
    },
    onError: () => {
      snackbar.fail(dict.failedToSave);
    },
  });

  return (
    <Root>
      <WHeaderNavigation
        title={dict.newAnnouncement}
        navigation={[
          {label: dict.navigation1},
          {label: dict.navigation2, toPath: '/building-tenant-management/announcement'},
        ]}
        actions={{
          items: [
            {
              label: dict.cancel,
              action: () => navigate({to: '/building-tenant-management/announcement'}),
              buttonColor: 'secondary',
              disabled: isPending,
            },
            {
              label: dict.save,
              // 新規作成画面で「保存」を押したときは、特にダイアログでの注意喚起不要でそのまま保存してよい
              action: () => save({publishStatus: 'draft', scheduledAt: null}),
              buttonColor: 'secondary',
              disabled: !bothChanged || isTitleOverFifty || isPending,
              running: isPending,
            },
            {
              label: dict.toPublishSetting,
              action: onOpenPublishSetting,
              buttonColor: 'primary',
              disabled: !bothChanged || isTitleOverFifty || isPending,
            },
          ],
        }}
      />

      <Layout>
        <AnnouncementTitleAndContentForm
          isTitleOverFifty={isTitleOverFifty}
          titleRef={titleRef}
          editorRef={editorRef}
          editorContent={''}
        />
      </Layout>

      <AnnouncementPublishSettingFormDialog
        open={openPublishSetting}
        inputValue={{publishStatus: 'draft', scheduledAt: null}}
        onOk={value => save(value)}
        onClose={onClosePublishSetting}
        isPending={isPending}
      />

      <AnnouncementConfirmCancelDialog isPending={isPending} hasChanged={hasChanged} />
    </Root>
  );
}
